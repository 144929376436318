/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, SEO, useMainArticleImageUrl, useArticleSchema } from '@core';
import { SEOStructuredData } from 'src/components/core/layout/SEOStructuredData';
import { formatPageMetaOgImage } from 'src/components/core/layout/usePageMetaAttributes';
import { SlugContext } from '../../core/layout/SlugContext';
import { PostDetails, PostDetailsProps } from './PostDetails';

const Page = ({ pageContext }: PostDetailsProps) => {
  const articleSchema = useArticleSchema(pageContext);
  const primaryImage = useMainArticleImageUrl(pageContext);

  return (
    <SlugContext.Provider value={pageContext.slugs}>
      <Layout>
        <SEO title={pageContext.title} description={pageContext.subtitle} meta={formatPageMetaOgImage(primaryImage)} />
        <SEOStructuredData schema={articleSchema} />
        <PostDetails {...pageContext} />
      </Layout>
    </SlugContext.Provider>
  );
};

export default Page;
